<template>
  <div class="modal-shell" :class="`modal-shell--${size} `">
    <header class="modal-shell__header">
      <p v-if="title" class="flex items-center font-medium ps-4">
        {{ title }}
      </p>
      <div class="flex-grow">
        <slot name="header"> </slot>
      </div>
      <button class="modal-shell__close" @click="close()">
        <Icon name="MdiClose" class="block w-5 h-5" />
      </button>
    </header>

    <!-- Loading -->
    <div v-if="loading" class="flex items-center justify-center h-full py-24">
      <Icon
        name="MdiLoading"
        class="w-8 h-8 text-gray-300 animate animate-spin"
      />
    </div>

    <!-- Content -->
    <template v-else>
      <div class="modal-shell__body">
        <slot />
      </div>
      <footer v-if="footer" class="modal-shell__footer">
        <slot name="footer">
          <div>
            <slot name="footer-left"> </slot>
          </div>
          <div class="flex justify-end p-3 space-x-2 rtl:space-x-reverse">
            <slot name="footer-actions"> </slot>
          </div>
        </slot>
      </footer>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    close: {
      type: Function,
      default: null,
    },
    footer: Boolean,
    size: {
      type: String,
      default: "md",
    },
    loading: Boolean,
  },
};
</script>

<style>
.modal-shell {
  @apply flex flex-col rounded-t-2xl overflow-hidden bg-white ms-auto me-auto mt-10 md:mb-10 md:rounded-md dark:bg-gray-900 dark:text-gray-300;
  max-height: calc(100vh - 180px);
}
.modal-shell__header {
  @apply h-12 border-b border-gray-200 flex items-stretch justify-between dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300;
}
.modal-shell__close {
  outline: none !important;
  @apply outline-none w-12 h-12 flex justify-center items-center text-gray-400 hover:text-gray-500;
}
.modal-shell__body {
  @apply flex-grow overflow-auto;
}

.modal-shell--sm {
  width: 320px;
  max-width: 100%;
}

.modal-shell--md {
  width: 480px;
  max-width: 100%;
}

.modal-shell--lg {
  width: 640px;
  max-width: 100%;
}

.modal-shell--xl {
  width: 768px;
  max-width: 100%;
}

.modal-shell--fs {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
}

.modal-shell__footer {
  @apply flex justify-between items-center border-t border-gray-200;
}
</style>
