<template>
  <Teleport v-if="isOpen" to="body">
    <!-- Modal -->
    <div :id="id" class="fixed inset-0 z-40" :class="classList">
      <!-- Overlay -->
      <div class="fixed inset-0 bg-black bg-opacity-80"></div>

      <!-- Modal Wrapper -->
      <div
        class="absolute left-0 right-0 bottom-0 z-50 mx-auto flex items-center md:p-5 md:my-auto md:top-0"
        @click.self="overlayClose"
      >
        <slot :close="close" :data="scope"></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    closeOnOverlay: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["open", "close"],
  data() {
    return {
      isOpen: false,
      scope: null,
    };
  },

  computed: {
    classList() {
      const classes = [];
      if (this.isOpen) classes.push(`modal--show`);
      return classes;
    },
  },

  mounted() {
    document.addEventListener("keyup", (e) => {
      if (e.key === 27 && this.closeOnEsc) {
        this.close();
      }
    });
    this.$nuxt.hook("modal-open", (id, scope) => {
      this.scope = scope;
      this.open(id);
    });
    this.$nuxt.hook("modal-close", (id) => {
      this.scope = null;
      this.close(id);
    });
  },

  methods: {
    open(id) {
      if (!id) return;
      if (this.id === id) {
        this.isOpen = true;
        this.$emit("open", this.scope);
      } else {
        this.isOpen = false;
      }
    },
    close(id) {
      let shouldClose = false;
      if (id) {
        if (this.id === id) {
          shouldClose = true;
        }
      } else {
        shouldClose = true;
      }
      if (shouldClose) {
        this.isOpen = false;
        this.$emit("close");
      }
    },
    overlayClose() {
      if (this.closeOnOverlay) {
        this.close();
      }
    },
  },
};
</script>
